<template>
    <!-- <div style="width: 100%; height: 100%;"> -->
        
    <v-carousel cycle interval="10000" :show-arrows="['xs', 'sm'].includes($vuetify.breakpoint.name)" hide-delimiters id="carousel" height="auto">
        <v-carousel-item v-for="(item, i) in items" :key="i" style="">
            <!-- <v-card tile>
                <v-img 
                    :src="checkImagePath(item.image_path)" 
                    lazy-src="@/assets/loading_img.jpg"
                    position="top"
                    class="ma-0 white--text align-end"
                    height="550"
                >   
                    <v-row class="pa-0">
                        <v-col v-for="(sub_image, index) in item.event_activity_images" :key="index" cols="12" sm="12" md="12" lg="12">
                            <v-img 
                                :src="checkImagePath(sub_image.image_path)" 
                                height="80%"
                                lazy-src="@/assets/loading_img.jpg"
                                width="65%"
                                
                                style="margin-bottom:15%;margin-left:18%; "
                                contain
                            />
                        </v-col>
                    </v-row>
                    <v-card color="#00000080">
                        <p class="ma-0 pa-2" :style="`font-size:${font_size()}px;`">{{ item.description }}</p>
                    </v-card>
                </v-img>
            </v-card> -->
            <v-card  color="transparent" tile>
                <v-card-text>
                    <v-img 
                        :src="checkImagePath(item.image_path)" 
                        :lazy-src="checkImagePath(GET_PREFERENCES.find(x => x.parameter == 'system_lazy_image')?.value ?? null)"
                        class="ma-0 white--text align-end"
                        style="position:inherit; padding-bottom:30%;"
                        height="auto"
                        width="100%"
                        contain
                    >   
                        <v-container bg fill-height grid-list-md text-xs-center>
                            <v-layout row wrap align-center>
                                <v-flex>
                                    <v-card
                                        class="mx-auto"
                                        max-width="374"
                                        color="transparent"
                                        tile
                                        elevation="0"
                                        fill-height
                                        grid-list-md
                                        text-xs-center
                                    >
                                        <v-img 
                                            v-for="(sub_image, index) in item.event_activity_images" :key="index"
                                            :src="checkImagePath(sub_image.image_path)" 
                                            height="auto"
                                            max-height="300"
                                            :lazy-src="checkImagePath(GET_PREFERENCES.find(x => x.parameter == 'system_lazy_image')?.value ?? null)"
                                            class="img-fluid"
                                            width="100%"
                                            contain
                                        >
                                        </v-img>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <p  
                            :style="
                            ` 
                                position: absolute;
                                bottom: -5px; 
                                left: 0;
                                background: rgb(0, 0, 0);
                                background: rgba(0, 0, 0, 0.5); /* Black see-through */
                                width: inherit;
                                transition: .5s ease;
                                padding: 0px;
                                text-align: center;`
                            "
                        >{{ item.description }}
                        </p>
                    </v-img>
                </v-card-text>
            </v-card>
        
        </v-carousel-item>
    </v-carousel>    
    <!-- </div> -->
</template>
<script>
import SharedFunctionsComponent from '@/components/shared/SharedFunctionsComponent.vue'
import { mapGetters } from 'vuex';
export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
            items: []
        };
    },
    computed: {
        ...mapGetters([
            'GET_EVENT_ID',
            'GET_PREFERENCES'
        ])
    },
    created() {
    },
    async mounted() {
        await this.getActivities()
    },
    methods: {
        async getActivities(){
            let payload = {
                url: "getEventActivities",
                event_id: this.GET_EVENT_ID
            }
            await this.$store.dispatch('urlGet', payload).then((res)=>{
                this.items = res.data
            })
        },
        bot_margin(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "50px";
                case 'sm': return "100px"
                case 'md': return "100%"
                case 'lg': return "100%"
                case 'xl': return "100%"
            }
        },
        font_size() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "11"
                case 'sm': return "11"
                case 'md': return "15"
                case 'lg': return "15"
                case 'xl': return "15"
            }
        },
        imageHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '400'
                case 'sm': return '400'
                case 'md': return '300'
                case 'lg': return '300'
                case 'xl': return '300'
            }
        },
        firstImageHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '565'
                case 'sm': return '1100'
                case 'md': return '650'
                case 'lg': return '700'
                case 'xl': return '750'
            }

        },
        pos(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '38%'
                case 'sm': return '45%'
                case 'md': return '60%'
                case 'lg': return '60%'
                case 'xl': return '60%'
            }
        },
    },
    watch: {
        GET_EVENT_ID:{
            handler(val){
                if(val){
                    this.getActivities();
                }
            }
        },
    },
};
</script>
<style scoped>
</style>
