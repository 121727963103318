<template>
    <v-container class="pb-0 mt-1" fluid>
        <v-row no-gutters>
            <v-col cols="12" class="pa-0 pb-2">
               <v-carousel v-model="currentWindow" cycle interval="10000" :show-arrows="!['xs', 'sm'].includes($vuetify.breakpoint.name)" hide-delimiters id="carousel" height="auto">
                    <v-carousel-item v-for="(item, i) in items" :key="i" style="">
                        <v-card tile height="400">
                            <v-img 
                                :src="checkImagePath(item.image_path)"
                                :lazy-src="checkImagePath(GET_PREFERENCES.find(x => x.parameter == 'system_lazy_image')?.value ?? null)"
                                height="inherit"
                                width="inherit"
                                contain
                                class="ma-0 white--text align-end"
                            >   
                            </v-img>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>    
            </v-col>
            <v-col cols="12" class="pa-0 pb-2 text-center">
                <v-file-input
                    v-model="images"
                    @change="uploadImageMoments"
                    append-icon="mdi-image-plus-outline"
                    label="Upload Image"
                    type="file"
                    name="files"
                    :multiple="true"
                    accept="image/*"
                />
                <!-- <v-btn
                    style="opacity:1; cursor: pointer;"
                    class="ma-2"
                    color="#2590ff"
                    text
                >
                    <label for="upload-files-moments"><span class="upload-files-moments"><v-icon x-large>mdi-image-plus-outline</v-icon></span></label>
                </v-btn>
                <input  class="d-none" id="upload-files-moments" type="file" ref="fileInput" @change="uploadImageMoments" accept="image/png, image/jpeg"/> -->
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from '@/components/shared/SharedFunctionsComponent.vue'

export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
            items: [],
            moments_image: '',
            images: [],
            disabled_view: false,
            form: {},
            isHover: false,
            currentWindow: 0,
        };
    },
    computed: {
        ...mapGetters([
            'GET_CURRENT_USER',
            'GET_PREFERENCES'
        ])
    },
    created() {
    },
    async mounted() {
        await this.getUserMoments()
    },
    methods: {
        async getUserMoments(){
            let payload = {
                url: "attendeeShareMoments",
                NominatorID: this.GET_CURRENT_USER
            }
            await this.$store.dispatch('urlGet', payload).then((res)=>{
                this.items = res.data
            })
        },
        font_size() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "11"
                case 'sm': return "11"
                case 'md': return "15"
                case 'lg': return "15"
                case 'xl': return "15"
            }
        },
        imageHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '400'
                case 'sm': return '400'
                case 'md': return '300'
                case 'lg': return '300'
                case 'xl': return '300'
            }
        },
        pos(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '38%'
                case 'sm': return '45%'
                case 'md': return '60%'
                case 'lg': return '60%'
                case 'xl': return '60%'
            }
        },
        async uploadImageMoments(event) {
            Swal.showLoading()
            Swal.fire({
                title: 'Uploading Image',
                allowOutsideClick: false,
                showCancelButton: false,
                showConfirmButton: false,
            });
            let files = event;
            let uploadCounter = files.length;  // Initialize the counter with the number of files

            for (let file of files) {
                let reader = new FileReader();
                reader.onload = () => {
                    let img = new Image();
                    img.onload = async () => {
                        // Create a canvas element
                        let canvas = document.createElement('canvas');
                        let ctx = canvas.getContext('2d');

                        // Set maximum dimensions for the image
                        let maxWidth = 1920;  // Increase if higher quality is needed
                        let maxHeight = 1080; // Increase if higher quality is needed
                        let width = img.width;
                        let height = img.height;

                        // Calculate the new dimensions while maintaining the aspect ratio
                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        // Draw the image on the canvas
                        ctx.drawImage(img, 0, 0, width, height);

                        // Get the resized image as a Base64 string
                        let resizedImage = canvas.toDataURL('image/jpeg', 0.8);  // 0.8 is the quality of the output image

                        let payload = {
                            url: "attendeeShareMoments",
                            prize_image: resizedImage,
                            nominatorID: this.GET_CURRENT_USER,
                        };

                        try {
                            await this.$store.dispatch("urlPost", payload);
                            uploadCounter--;

                            if (uploadCounter === 0) {
                                this.images = null;
                                await this.getUserMoments();
                                this.currentWindow = 0;
                                Swal.close();
                                Swal.fire({
                                    title: "Image Uploaded Successfully!",
                                    icon: 'success',
                                    allowOutsideClick: false,
                                    customClass: {
                                        confirmButton: 'orange-button',
                                    },
                                });
                            }
                        } catch (error) {
                            console.error('Error uploading image', error);
                            Swal.close();
                        }
                    };
                    img.src = reader.result;
                };
                reader.readAsDataURL(file);
            }
        }
        // async uploadImageMoments(event) {
        //     Swal.showLoading()
        //     Swal.fire({
        //         title: 'Uploading Image',
        //         allowOutsideClick: false,
        //         showCancelButton: false,
        //         showConfirmButton: false,
        //     });
		//     let file = event;
        //     let uploadCounter = file.length;  // Initialize the counter with the number of files
        //     for (let f of file) {
        //         let reader = new FileReader();
        //         reader.onload = () => {
        //             let prize_image = reader.result;

        //             let payload = {
        //                 url: "attendeeShareMoments",
        //                 prize_image: prize_image,
        //                 nominatorID: this.GET_CURRENT_USER,
        //             };

        //             this.$store.dispatch("urlPost", payload)
        //                 .then(async response => {
        //                     uploadCounter--;
                            
        //                     if (uploadCounter === 0) {  
        //                         this.images = null;
        //                         await this.getUserMoments();
        //                         this.currentWindow = 0;
        //                         Swal.close();
        //                         Swal.fire({
        //                             title: "Image Uploaded Successfully!",
        //                             icon: 'success',
        //                             allowOutsideClick: false,
        //                             customClass: {
        //                                 confirmButton: 'orange-button',
        //                             },
        //                         });
        //                     }
        //                 })
        //                 .catch(error => {
        //                     console.error('Error uploading image', error);
        //                     Swal.close();
        //                 });
        //         };
        //         reader.readAsDataURL(f);
        //     }
        // }
    },
    watch: {
    },
};
</script>
<style scoped>

</style>
