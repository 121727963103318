<template>
    <v-carousel cycle interval="10000" :show-arrows="!['xs', 'sm'].includes($vuetify.breakpoint.name)" hide-delimiters id="carousel" height="auto">
        <v-carousel-item v-for="(item, i) in items" :key="i" style="">
            <v-card tile>
                <v-img 
                    :src="checkImagePath(item.image_path)" 
                    :lazy-src="checkImagePath(GET_PREFERENCES.find(x => x.parameter == 'system_lazy_image')?.value ?? null)"
                    height="inherit"
                    contain
                    class="ma-0 white--text align-end  mt-10"
                >   
                </v-img>
            </v-card>
        </v-carousel-item>
    </v-carousel>    
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            items: []
        };
    },
    computed: {
        ...mapGetters([
            'GET_EVENT_ID',
            'GET_PREFERENCES'
        ])
    },
    created() {
    },
    async mounted() {
        await this.getActivities()
    },
    methods: {
        async getActivities(){
            let payload = {
                url: "getEventSitemaps",
                event_id: this.GET_EVENT_ID
            }
            await this.$store.dispatch('urlGet', payload).then((res)=>{
                this.items = res.data
            })
        },
        font_size() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "11"
                case 'sm': return "11"
                case 'md': return "15"
                case 'lg': return "15"
                case 'xl': return "15"
            }
        },
        imageHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '400'
                case 'sm': return '400'
                case 'md': return '300'
                case 'lg': return '300'
                case 'xl': return '300'
            }
        },
        pos(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '38%'
                case 'sm': return '45%'
                case 'md': return '60%'
                case 'lg': return '60%'
                case 'xl': return '60%'
            }
        },
        checkImagePath(image_path) {
            return image_path == null
                ? process.env.VUE_APP_API_HOST + "/images/no-image.png"
                : process.env.VUE_APP_API_HOST + "/" + image_path
        },
    },
    watch: {
        GET_EVENT_ID:{
            handler(val){
                if(val){
                    this.getActivities();
                }
            }
        },
    },
};
</script>
<style scoped>

</style>
