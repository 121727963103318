var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-carousel',{attrs:{"cycle":"","interval":"10000","show-arrows":['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name),"hide-delimiters":"","id":"carousel","height":"auto"}},_vm._l((_vm.items),function(item,i){return _c('v-carousel-item',{key:i},[_c('v-card',{attrs:{"color":"transparent","tile":""}},[_c('v-card-text',[_c('v-img',{staticClass:"ma-0 white--text align-end",staticStyle:{"position":"inherit","padding-bottom":"30%"},attrs:{"src":_vm.checkImagePath(item.image_path),"lazy-src":_vm.checkImagePath(_vm.GET_PREFERENCES.find(x => x.parameter == 'system_lazy_image')?.value ?? null),"height":"auto","width":"100%","contain":""}},[_c('v-container',{attrs:{"bg":"","fill-height":"","grid-list-md":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"374","color":"transparent","tile":"","elevation":"0","fill-height":"","grid-list-md":"","text-xs-center":""}},_vm._l((item.event_activity_images),function(sub_image,index){return _c('v-img',{key:index,staticClass:"img-fluid",attrs:{"src":_vm.checkImagePath(sub_image.image_path),"height":"auto","max-height":"300","lazy-src":_vm.checkImagePath(_vm.GET_PREFERENCES.find(x => x.parameter == 'system_lazy_image')?.value ?? null),"width":"100%","contain":""}})}),1)],1)],1)],1),_c('p',{style:(` 
                            position: absolute;
                            bottom: -5px; 
                            left: 0;
                            background: rgb(0, 0, 0);
                            background: rgba(0, 0, 0, 0.5); /* Black see-through */
                            width: inherit;
                            transition: .5s ease;
                            padding: 0px;
                            text-align: center;`)},[_vm._v(_vm._s(item.description)+" ")])],1)],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }